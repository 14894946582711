import { createTheme } from '@mui/material/styles';

import { borders } from './borders';
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';

export const theme = createTheme({
  iconSize: {
    sm: 16,
    md: 24,
    lg: 32,
  },
  palette,
  typography,
  spacing: [0, 2, 4, 8, 12, 16, 20, 24, 32, 40, 48, 60],
  borders,
  breakpoints: {
    values: {
      xs: 480,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  shadows,
});
