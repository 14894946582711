import { CssBaseline, ThemeProvider } from '@mui/material';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { theme } from '@apps/checkout-components';

import { initErrorTracking, initMixpanel } from '@apps/checkout-utils';

import { SENTRY_DSN } from '@chk/env-variables';

import App from './app/app';

initErrorTracking({ dsn: SENTRY_DSN });
initMixpanel();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </StrictMode>
);
