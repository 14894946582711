import { Paper, styled, useTheme } from '@mui/material';

import { FALLBACK_ICON_TEST_ID } from '../../global.constants';
import { ChkIconBank } from '../icons/ChkIconBank';
import type { ChkLogoWrapperProps } from './ChkLogoWrapper.types';

export const ChkLogoWrapper = ({
  altText = '',
  elevation = 0,
  iconUrl,
  ...rest
}: ChkLogoWrapperProps) => {
  const theme = useTheme();

  return (
    <StyledPaper iconUrl={iconUrl} elevation={elevation} {...rest}>
      {iconUrl ? (
        <img src={iconUrl} alt={altText} />
      ) : (
        <ChkIconBank
          color={theme.palette.steel100}
          size="lg"
          data-testid={FALLBACK_ICON_TEST_ID}
        />
      )}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'iconUrl',
})<{ iconUrl: ChkLogoWrapperProps['iconUrl'] }>(({ theme, iconUrl }) => ({
  width: '5.6rem',
  aspectRatio: '1 / 1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.borders.borderRadius3,
  overflow: 'hidden',
  backgroundColor: iconUrl ? 'transparent' : theme.palette.navy50,

  img: {
    width: '100%',
  },
}));
