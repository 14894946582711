import { Box, styled } from '@mui/material';

import type { ChkCardProps } from './ChkCard.types';

export const ChkCard = ({ children, ...rest }: ChkCardProps) => (
  <CardContainer {...rest}>{children}</CardContainer>
);

const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(5),
  borderRadius: theme.borders.borderRadius2,
  border: theme.borders.borderWidth1,
  borderColor: theme.palette.blue50,
}));
