import { ButtonBase, styled } from '@mui/material';
import { Box } from '@mui/system';

import { theme } from '../../theme';
import { ChkInput } from '../ChkInput/ChkInput';
import { ChkIconClose, ChkIconMagnifyingGlass } from '../icons';
import type { ChkSearchInputProps } from './ChkSearchInput.types';

export const ChkSearchInput = ({
  value,
  disabled,
  clearInputValue,
  startAdornment = <ChkIconMagnifyingGlass />,
  endAdornment = <ChkIconClose />,
  ...rest
}: ChkSearchInputProps) => (
  <StyledChkInput
    startAdornment={<StartIcon startAdornment={startAdornment} />}
    endAdornment={
      <EndIcon
        endAdornment={endAdornment}
        value={value}
        disabled={disabled}
        clearInputValue={clearInputValue}
      />
    }
    value={value}
    disabled={disabled}
    {...rest}
  />
);

const StyledChkInput = styled(ChkInput)(({ theme, value }) => ({
  color: value ? theme.palette.steel500 : theme.palette.steel300,
  '&.Mui-focused': {
    color: theme.palette.steel500,
  },
  '&.Mui-disabled': {
    color: theme.palette.steel200,
  },
}));

const StartIcon = ({
  startAdornment,
}: Pick<ChkSearchInputProps, 'startAdornment'>) => (
  <Box
    sx={{
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginRight: theme.spacing(3),
    }}
  >
    {startAdornment}
  </Box>
);

const EndIcon = ({
  value,
  endAdornment,
  clearInputValue,
  disabled,
}: Pick<
  ChkSearchInputProps,
  'value' | 'endAdornment' | 'clearInputValue' | 'disabled'
>) =>
  Boolean(value) && (
    <ButtonBase
      sx={{ marginLeft: theme.spacing(3) }}
      disabled={disabled}
      onClick={() => {
        clearInputValue();
      }}
    >
      {endAdornment}
    </ButtonBase>
  );
