export const colorsNames = [
  'blue50',
  'blue100',
  'blue200',
  'blue300',
  'blue400',
  'blue500',
  'blue600',
  'blue700',
  'blue800',
  'blue900',
  'red50',
  'red100',
  'red200',
  'red300',
  'red400',
  'red500',
  'red600',
  'red700',
  'red800',
  'red900',
  'navy25',
  'navy10',
  'navy50',
  'navy100',
  'navy200',
  'navy300',
  'navy400',
  'navy500',
  'navy600',
  'navy700',
  'navy800',
  'navy900',
  'orange50',
  'orange100',
  'orange200',
  'orange300',
  'orange400',
  'orange500',
  'orange600',
  'orange700',
  'orange800',
  'orange900',
  'green50',
  'green100',
  'green200',
  'green300',
  'green400',
  'green500',
  'green600',
  'green700',
  'green800',
  'green900',
  'steel50',
  'steel100',
  'steel200',
  'steel300',
  'steel400',
  'steel500',
  'steel600',
  'steel700',
  'steel800',
  'steel900',
  'white',
  'black',
] as const;

type Colors = (typeof colorsNames)[number];

declare module '@mui/material/styles/createPalette' {
  type PaletteColors = {
    [C in Colors]: string;
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends PaletteColors {}

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends PaletteColors {}
}

export const palette = {
  blue50: '#e8effa',
  blue100: '#b8cff0',
  blue200: '#96b7e9',
  blue300: '#6696df',
  blue400: '#4982d9',
  blue500: '#1b63cf',
  blue600: '#195abc',
  blue700: '#134693',
  blue800: '#0f3672',
  blue900: '#0b2a57',
  red50: '#fbe8e8',
  red100: '#f4b9b9',
  red200: '#ee9797',
  red300: '#e76767',
  red400: '#e24949',
  red500: '#db1c1c',
  red600: '#c71919',
  red700: '#9b1414',
  red800: '#780f0f',
  red900: '#5c0c0c',
  navy10: '#fdfdff',
  navy25: '#f4f4f7',
  navy50: '#e8e9ee',
  navy100: '#b8baca',
  navy200: '#9599b0',
  navy300: '#656b8c',
  navy400: '#474e75',
  navy500: '#192253',
  navy600: '#171f4c',
  navy700: '#12183b',
  navy800: '#0e132e',
  navy900: '#0b0e23',
  orange50: '#fff6e6',
  orange100: '#ffe4b0',
  orange200: '#ffd78a',
  orange300: '#fec554',
  orange400: '#feb933',
  orange500: '#fea800',
  orange600: '#e79900',
  orange700: '#b47700',
  orange800: '#8c5c00',
  orange900: '#6b4700',
  green50: '#e9f6f6',
  green100: '#bbe4e3',
  green200: '#9bd7d6',
  green300: '#6dc4c3',
  green400: '#51b9b7',
  green500: '#25a7a5',
  green600: '#229896',
  green700: '#1a7775',
  green800: '#145c5b',
  green900: '#104645',
  steel50: '#e8e9ee',
  steel100: '#b7bac9',
  steel200: '#9599af',
  steel300: '#646b8a',
  steel400: '#464e74',
  steel500: '#182251',
  steel600: '#161f4a',
  steel700: '#11183a',
  steel800: '#0d132d',
  steel900: '#0a0e22',
  white: '#ffffff',
  black: '#000000',
};
