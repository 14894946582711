import { useAsync, useAsyncAbortable } from '@react-hookz/web';

import { asyncFetch } from './useApi.utils';

/**
 * Hook to (attempt to) uniform API usage
 * @param url the API url to be used
 * @param options any option `fetch` can handle
 * @returns
 */

const useApi = <T>(url: string, options?: RequestInit) => {
  const [state, actions, meta] = useAsync<T | null>((args) => {
    return asyncFetch(url, {
      ...options,
      ...Object.assign({}, args),
    });
  });

  const { execute, ...restActions } = actions;

  return {
    ...state,
    ...meta,
    isLoading: state.status === 'loading',
    executeRequest: execute,
    ...restActions,
  };
};

const useApiAbortable = <T>(url: string, options?: RequestInit) => {
  const [state, actions, meta] = useAsyncAbortable<T | null>((signal, args) => {
    return asyncFetch(url, {
      ...options,
      signal,
      ...Object.assign({}, args),
    });
  });

  const { execute, abort, ...restActions } = actions;

  return {
    ...state,
    ...meta,
    isLoading: state.status === 'loading',
    executeRequest: execute,
    abort,
    ...restActions,
  };
};

export { useApi, useApiAbortable };
