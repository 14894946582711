import { Box, styled, useTheme } from '@mui/material';
import type { ReactNode } from 'react';
import { useEffect, useMemo } from 'react';

import {
  ChkButton,
  ChkPageWrapper,
  ChkStatusScreen,
} from '@apps/checkout-components';

import { getComponentByStatus } from '@apps/checkout-contexts';

import {
  API_ENDPOINTS,
  getPaymentRequestId,
  trackEvent,
  useApi,
} from '@apps/checkout-utils';

import {
  ORIGIN_APP,
  REQUEST_ARGS,
  STATUS_APP_EVENTS,
} from '../global.constants';
import type {
  PaymentStatus,
  RedeemBody,
  RedeemResponse,
} from '../global.types';
import { getAllQueryParams } from '../utils/index.utils';

export function App() {
  const { spacing, typography } = useTheme();
  const { isLoading, executeRequest, result } = useApi<RedeemResponse>(
    API_ENDPOINTS.redeemPayment(),
    REQUEST_ARGS
  );

  const STATUS_MAP = useMemo(
    () =>
      ({
        // TODO: All these views might be moved to their own files to handle thier own case
        // OR be controlled by some config-by-status object if they happen to be too repetitive
        SUCCEED: (
          <ChkStatusScreen
            animation="success"
            loop={false}
            title="Payment success!"
            text="Woohoo, your payment has been successful."
          >
            <ChkButton
              variant="contained"
              sx={{
                marginTop: spacing(6),
                fontWeight: typography.fontWeightBold,
                width: '100%',
              }}
              onClick={() => {
                window.location.href = result?.details.redirectUrl as string;
              }}
            >
              Restart the demo
            </ChkButton>
          </ChkStatusScreen>
        ),
        FAILED: (
          <ChkStatusScreen
            animation="failed"
            loop={false}
            title="Payment Failed"
            text="Oops, it appears there's an issue. No funds have moved from your account."
          />
        ),
        PENDING: (
          <ChkStatusScreen
            animation="pending"
            title="Processing transaction..."
            text="Please wait, your transaction is being processed."
          />
        ),
        CANCELLED_BY_USER: (
          <ChkStatusScreen
            animation="failed"
            loop={false}
            title="Payment cancelled"
            text="Sorry to see you go. No funds have moved from your account."
          />
        ),
      } satisfies Record<PaymentStatus, ReactNode>),
    [spacing, result?.details.redirectUrl, typography]
  );

  const view = useMemo(
    () =>
      getComponentByStatus({
        status: result?.status,
        componentsMap: STATUS_MAP,
        isLoading,
        loadingComponent: <ChkStatusScreen animation="loading" />,
      }),
    [isLoading, STATUS_MAP, result?.status]
  );

  useEffect(() => {
    executeRequest({
      body: JSON.stringify({
        paymentId: getPaymentRequestId(),
        params: getAllQueryParams(document.URL),
      } satisfies RedeemBody),
    });
  }, [executeRequest]);

  useEffect(() => {
    const eventsMap = {
      SUCCEED: STATUS_APP_EVENTS.SUCCESS_SCREEN,
      FAILED: STATUS_APP_EVENTS.FAILURE_SCREEN,
      PENDING: STATUS_APP_EVENTS.PENDING_SCREEN,
      CANCELLED_BY_USER: STATUS_APP_EVENTS.CANCEL_SCREEN,
    };

    if (result?.status && eventsMap[result?.status]) {
      trackEvent(eventsMap[result?.status], { originApp: ORIGIN_APP });
    }
  }, [result?.status]);

  return (
    <ChkPageWrapper sx={{ overflow: 'auto' }}>
      <StyledPageContainer>{view}</StyledPageContainer>
    </ChkPageWrapper>
  );
}

export default App;

const StyledPageContainer = styled(Box)(({ theme }) => ({
  padding: [0, theme.spacing(6), theme.spacing(6)].join(' '),

  [theme.breakpoints.up('xs')]: {
    padding: [theme.spacing(6), theme.spacing(8)].join(' '),
    margin: ['auto', theme.spacing(9)].join(' '),
  },
}));
