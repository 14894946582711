import { Backdrop, Drawer, styled } from '@mui/material';

import type { ChkDrawerProps } from './ChkDrawer.types';

export const ChkDrawer = ({
  BackdropProps,
  children,
  hideBackdrop,
  open,
  ...rest
}: ChkDrawerProps) => (
  <>
    {!hideBackdrop && (
      <Backdrop
        {...BackdropProps}
        open={Boolean(open)}
        sx={{
          ...BackdropProps?.sx,
          position: 'absolute',
        }}
      />
    )}

    <StyledDrawer
      {...rest}
      anchor="bottom"
      elevation={0}
      open={open}
      variant="persistent"
    >
      {children}
    </StyledDrawer>
  </>
);

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  '.MuiDrawer-paper': {
    position: 'static',
    padding: `${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(7)}`,
    background: theme.palette.navy25,
    borderRadius: `${theme.borders.borderRadius2} ${theme.borders.borderRadius2} 0 0`,
    border: 'none',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: '24px', // height of the bottom demo banner
    },
  },
}));
