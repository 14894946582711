import { Box, styled, Typography } from '@mui/material';

import { BANK_NAME_AT_SUMMARY_PAGE_TEST_ID } from '../../global.constants';
import { ChkButton } from '../ChkButton/ChkButton';
import { ChkCard } from '../ChkCard/ChkCard';
import { ChkLogoWrapper } from '../ChkLogoWrapper/ChkLogoWrapper';
import { ChkIconPencil } from '../icons';
import type { ChkBankCardProps } from './ChkBankCard.types';

export const ChkBankCard = ({
  bankName,
  iconUrl,
  disabled,
  onChangeBankClick,
  ...rest
}: ChkBankCardProps) => (
  <StyledChkCard disabled={disabled} {...rest}>
    <ChkLogoWrapper
      altText={`${bankName} logo`}
      className="bank-logo-container"
      iconUrl={iconUrl}
    />

    <ContentBox>
      <Typography variant="body3" color="steel200">
        Paying with
      </Typography>

      <Typography
        variant="body2"
        color="steel500"
        sx={{ letterSpacing: '-0.3px' }}
        data-testid={BANK_NAME_AT_SUMMARY_PAGE_TEST_ID}
      >
        {bankName}
      </Typography>
    </ContentBox>

    {!disabled && (
      <ChkButton
        onClick={onChangeBankClick}
        startIcon={<ChkIconPencil />}
        variant="text"
      >
        <Typography variant="body2" fontWeight="bold">
          Change
        </Typography>
      </ChkButton>
    )}
  </StyledChkCard>
);

const StyledChkCard = styled(ChkCard, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled: ChkBankCardProps['disabled'] }>(({ disabled, theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  ...(disabled && { pointerEvents: 'none' }),
  '.bank-logo-container': {
    width: '4.8rem',
  },

  '.MuiButtonBase-root': {
    padding: theme.spacing(0),
    letterSpacing: '-0.3px',

    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  flex: 1,
  marginLeft: theme.spacing(5),
  marginRight: theme.spacing(3),
}));
