export const getAllQueryParams = (url: string): Record<string, string> => {
  try {
    const searchParams = new URL(url).searchParams;

    const params = Array.from(searchParams.entries()).reduce(
      (acc, [k, v]) => ({ ...acc, [k]: v }),
      {}
    );

    return params;
  } catch (error) {
    console.error(error);

    return {};
  }
};
