export const ChkIconVoltLogo = () => (
  <svg
    width="54"
    height="26"
    viewBox="0 0 54 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9221 7.93347L8.45929 25.857L0 7.93347H3.74717L8.45929 18.1572L13.1779 7.93347H16.9221Z"
      fill="#192353"
    />
    <path
      d="M16.9213 0.142944H0.00390625V3.28034H16.9213V0.142944Z"
      fill="#192353"
    />
    <path
      d="M40.9358 0.142944H37.501V25.4198H40.9358V0.142944Z"
      fill="#192353"
    />
    <path
      d="M49.9573 7.93226V3.28088H46.5225V7.93226H43.7207V11.0696H46.5225V25.4198H49.9573V11.0696C49.9573 11.0696 50.9904 11.0696 51.253 11.0696C52.0312 11.0696 52.736 10.6068 53.0809 9.8787C53.2303 9.56336 54.0002 7.93226 54.0002 7.93226H49.9573Z"
      fill="#192353"
    />
    <path
      d="M25.7144 7.75549C20.7753 7.75549 16.7715 11.7475 16.7715 16.6724C16.7715 21.5973 20.7753 25.5893 25.7144 25.5893C30.6536 25.5893 34.6574 21.5973 34.6574 16.6724C34.6574 11.7475 30.6536 7.75549 25.7144 7.75549ZM25.7144 22.2411C22.6471 22.2411 20.1612 19.7482 20.1612 16.6724C20.1612 13.5966 22.6477 11.1037 25.7144 11.1037C28.7818 11.1037 31.2676 13.5966 31.2676 16.6724C31.2676 19.7482 28.7812 22.2411 25.7144 22.2411Z"
      fill="#192353"
    />
  </svg>
);
