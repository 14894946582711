import type { ObjectKeys } from '@chk/tsUtils';
import type { ReactNode } from 'react';

import failedAnimation from '../../assets/animations/failed.json';
import loadingAnimation from '../../assets/animations/loading.json';
import pendingAnimation from '../../assets/animations/pending.json';
import successAnimation from '../../assets/animations/success.json';

export const ANIMATION_MAP = {
  pending: pendingAnimation,
  success: successAnimation,
  failed: failedAnimation,
  loading: loadingAnimation,
} as const;

export type ChkAnimationType = ObjectKeys<typeof ANIMATION_MAP>;

export type LottieAnimationProps = {
  src: ChkAnimationType;
  loop?: boolean;
  children?: ReactNode;
};
