import { Box, Container, styled, Typography } from '@mui/material';

import { ChkIconVoltLogo } from '../icons/ChkIconVoltLogo';
import type { ChkPageWrapperProps } from './ChkPageWrapper.types';

export const ChkPageWrapper = ({ children, ...rest }: ChkPageWrapperProps) => (
  <ViewportContainer>
    <PageContainer {...rest} maxWidth="xs" disableGutters>
      {children}
    </PageContainer>

    <FooterContainer>
      <StyledTypography variant="body2">Powered by</StyledTypography>

      <ChkIconVoltLogo />
    </FooterContainer>
    <DemoInfo>Demo experience</DemoInfo>
  </ViewportContainer>
);
const DemoInfo = styled(Typography)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  padding: theme.spacing(1),
  textAlign: 'center',
  textTransform: 'uppercase',
  fontSize: '12px',
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: '1.2px',
  background: '#6ae69e',
  pointerEvents: 'none',
  zIndex: 1000,
}));

const ViewportContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  background: theme.palette.navy50,
  boxSizing: 'border-box',
  [theme.breakpoints.up('xs')]: {
    paddingTop: theme.spacing(8),
  },
  paddingBottom: theme.spacing(6),
}));

const PageContainer = styled(Container)(({ theme }) => ({
  position: 'relative',
  height: '100vh',
  background: 'white',
  overflow: 'hidden',
  [theme.breakpoints.up('xs')]: {
    height: '82vh',
    minHeight: '70.7rem',
    maxHeight: '81.3rem',
    borderRadius: theme.borders.borderRadius2,
    boxShadow: '0 4px 80px 0 rgba(31, 48, 60, 0.05)',
  },
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${theme.spacing(8)} 0`,
  [theme.breakpoints.up('xs')]: {
    display: 'flex',
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  color: theme.palette.navy500,
  opacity: '0.5',
}));
