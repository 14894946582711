import { styled } from '@mui/material';
import InputBase from '@mui/material/InputBase';

import type { ChkInputProps } from './ChkInput.types';

export const ChkInput = ({ ...props }: ChkInputProps) => {
  return <StyledInput {...props} />;
};

const StyledInput = styled(InputBase)(({ theme }) => ({
  '& > .MuiInputBase-input': {
    padding: theme.spacing(0),
  },
  ...theme.typography.body2,
  fontWeight: theme.typography.fontWeightBold,
  height: '4.8rem',
  color: theme.palette.steel500,
  border: theme.borders.borderWidth1,
  borderRadius: theme.borders.borderRadius2,
  borderColor: theme.palette.navy50,
  backgroundColor: theme.palette.navy10,
  padding: [theme.spacing(4), theme.spacing(3)].join(' '),
  '&:hover, &:focus, &:focus-within': {
    borderColor: theme.palette.navy100,
  },
  '& > .MuiInputBase-input::placeholder': {
    color: theme.palette.steel300,
    letterSpacing: '-0.03rem',
    opacity: 1,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.steel50,
    borderColor: theme.palette.steel50,
    '& > .MuiInputBase-input::placeholder': {
      color: theme.palette.steel200,
    },
  },
}));
