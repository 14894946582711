import * as Sentry from '@sentry/browser';
import type { CaptureContext } from '@sentry/types';

import {
  APP_ENV,
  CHECKOUT_API_BASE_URL,
  CORE_API_BASE_URL,
  SENTRY_TRACES_SAMPLE_RATE,
  VERSION,
} from '@chk/env-variables';

import type { SentryContextProps, SentryInitProps } from './sentry.types';
import { isValidObject, sentrySanitizeUrl } from './sentry.utils';

const DEFAULT_SENTRY_CONFIG = {
  release: VERSION,
  integrations: [Sentry.browserTracingIntegration()],
  // target only backend services that also allow headers in CORS: "sentry-trace", "baggage"
  tracePropagationTargets: [
    sentrySanitizeUrl(CORE_API_BASE_URL),
    sentrySanitizeUrl(CHECKOUT_API_BASE_URL),
  ],
  tracesSampler: () => {
    // for further customization of sampling rate
    return SENTRY_TRACES_SAMPLE_RATE || 0;
  },
  ignoreErrors: [
    'top.GLOBALS', // NOTE: ignores random plugins/extensions,
    'Non-Error exception captured',
    `Failed to set the 'href' property on 'Location'`,
    `Blocked a frame with origin`,
  ],
  normalizeDepth: 4,
} satisfies Sentry.BrowserOptions;

export const initErrorTracking = ({ dsn, options }: SentryInitProps) => {
  const environment = APP_ENV;

  if (!dsn || environment === 'dev') {
    return;
  }

  Sentry.init({
    ...DEFAULT_SENTRY_CONFIG,
    ...options,
    dsn,
    environment,
  });
};

export const setSentryContext = ({ key, val }: SentryContextProps): void => {
  if (isValidObject(val)) {
    Sentry.setContext(key, val);
  }
};

export const setSentryTags = <T extends Record<string, string>>(tags: T) => {
  Object.entries(tags).forEach(([tagKey, tagValue]) =>
    Sentry.setTag(tagKey, tagValue)
  );
};

export const captureException = (
  error: Error,
  captureContext?: CaptureContext
): void => {
  Sentry.captureException(error, captureContext);
};

export const captureMessage = (message: string): void => {
  Sentry.captureMessage(message);
};
