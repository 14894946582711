export const X_VOLT_API_VERSION = '4';

export const REQUEST_ARGS: RequestInit = {
  method: 'POST',
  headers: {
    'X-Volt-Api-Version': X_VOLT_API_VERSION,
    'Content-type': 'application/json',
  },
};

export const STATUS_APP_EVENTS = {
  SUCCESS_SCREEN: 'Payment success screen view',
  PENDING_SCREEN: 'Payment pending screen view',
  FAILURE_SCREEN: 'Payment failure screen view',
  CANCEL_SCREEN: 'Payment cancel screen view',
} as const;

export const ORIGIN_APP = 'statusApp';
