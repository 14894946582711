import { CORE_API_BASE_URL } from '@chk/env-variables';

import { captureException } from '../sentry/sentry';

export class ErrorWithDetails extends Error {
  status: number;
  data: unknown;

  constructor(message: string, status: number, data: unknown) {
    super(message);
    this.status = status;
    this.data = data;
  }
}

export async function asyncFetch(url: string, init: RequestInit) {
  try {
    const response = await fetch(`${CORE_API_BASE_URL}${url}`, init);

    return parseResponse(response);
  } catch (error) {
    if (error instanceof ErrorWithDetails) {
      captureException(error);
    }

    return Promise.reject(error);
  }
}

export async function parseResponse(
  response: Pick<Response, 'text' | 'ok' | 'status' | 'statusText'>
) {
  const data = await response.text().then((text) => {
    if (!text) {
      return null;
    }

    try {
      return JSON.parse(text);
    } catch (err) {
      throw new ErrorWithDetails('Invalid JSON response', response.status, {
        text,
      });
    }
  });

  if (!response.ok) {
    throw new ErrorWithDetails(
      `${response.status} ${response.statusText}`,
      response.status,
      data
    );
  }

  return data;
}
