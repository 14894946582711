import { styled, Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';

import type { ChkListTextProps } from './ChkListItem.types';

export const ChkListText = ({
  primaryText,
  secondaryText,
  ...rest
}: ChkListTextProps) => {
  return (
    <StyledListText
      primary={
        <Typography variant="body1" sx={{ color: 'steel500' }}>
          {primaryText}
        </Typography>
      }
      secondary={
        <Typography
          variant="body2"
          sx={{ color: 'steel200', letterSpacing: '-0.3px' }}
        >
          {secondaryText}
        </Typography>
      }
      {...rest}
    />
  );
};

const StyledListText = styled(ListItemText)(({ theme }) => ({
  marginTop: 0,
  marginBottom: 0,
  marginLeft: theme.spacing(5),
}));
