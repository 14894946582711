declare module '@mui/material/styles' {
  interface Theme extends Borders {
    iconSize: {
      sm: number;
      md: number;
      lg: number;
    };
  }

  interface ThemeOptions extends Borders {
    iconSize: {
      sm: number;
      md: number;
      lg: number;
    };
  }

  export interface Borders {
    borders: {
      borderWidth0: string;
      borderWidth1: string;
      borderWidth2: string;
      borderWidth3: string;
      borderRadius0: string;
      borderRadius1: string;
      borderRadius2: string;
      borderRadius3: string;
      borderRadius4: string;
      borderRadius5: string;
      borderRadius6: string;
      borderRadius7: string;
    };
  }
}

export const borders = {
  borderWidth0: '0',
  borderWidth1: 'solid 0.1rem',
  borderWidth2: 'solid 0.2rem',
  borderWidth3: 'solid 0.4rem',
  borderRadius0: '0',
  borderRadius1: '0.4rem',
  borderRadius2: '0.8rem',
  borderRadius3: '1.2rem',
  borderRadius4: '1.6rem',
  borderRadius5: '2rem',
  borderRadius6: '3.2rem',
  borderRadius7: '6rem',
};
