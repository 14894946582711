export const API_ENDPOINTS = {
  getStatus: (paymentRequestId: string) =>
    `/checkout/${paymentRequestId}/status`,
  getBanks: (customerId: string, country: string, currency: string) =>
    `/checkout/banks/${customerId}/${country}/${currency}`,
  patchBank: (paymentRequestId: string) => `/checkout/${paymentRequestId}/bank`,
  process: (paymentId: string, paymentHash: string) =>
    `/checkout/${paymentId}/process?hash=${paymentHash}`,
  redeemPayment: () => `/checkout/redeem`,
  cancelPayment: (paymentId: string) =>
    `/payments/${window.btoa(paymentId)}/cancel`,
} as const;
