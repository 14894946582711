import { Box, styled, Typography } from '@mui/material';

import { BANK_NAME_AT_SELECTION_PAGE_TEST_ID } from '../../global.constants';
import { ChkLogoWrapper } from '../ChkLogoWrapper/ChkLogoWrapper';
import type { ChkBankTileProps } from './ChkBankTile.types';

const BOX_SIZE = '8.2rem';

export const ChkBankTile = ({
  bankName,
  iconUrl,
  ...rest
}: ChkBankTileProps) => (
  <StyledBox {...rest} role="button">
    <ChkLogoWrapper
      altText={`${bankName} logo`}
      className="icon-container"
      iconUrl={iconUrl}
    />

    <StyledTypography
      fontWeight="bold"
      variant="body3"
      data-testid={BANK_NAME_AT_SELECTION_PAGE_TEST_ID}
    >
      {bankName}
    </StyledTypography>
  </StyledBox>
);

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
  display: 'inline-block',
  width: BOX_SIZE,
  padding: `${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(2)}`,
  textAlign: 'center',
  borderRadius: theme.borders.borderRadius1,
  transition: '0.4s',
  cursor: 'pointer',
  ...(disabled && { pointerEvents: 'none', opacity: '0.4' }),
  '.icon-container': {
    margin: `0 auto ${theme.spacing(3)}`,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  whiteSpace: 'normal',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '1.4',
  color: theme.palette.steel500,
}));
