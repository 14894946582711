import type { CSSObject } from '@mui/material';
import { styled } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import { forwardRef } from 'react';

import { CHK_BUTTON_VARIANT } from './ChkButton.constants';
import type { ChkButtonProps, ChkButtonVariant } from './ChkButton.types';

export const ChkButton = forwardRef<HTMLButtonElement | null, ChkButtonProps>(
  ({ children, endIcon, startIcon, ...rest }: ChkButtonProps, ref) => (
    <StyledButton disableTouchRipple {...rest} ref={ref}>
      {startIcon && <StartIconRoot>{startIcon}</StartIconRoot>}
      {children}
      {endIcon && <EndIconRoot>{endIcon}</EndIconRoot>}
    </StyledButton>
  )
);

const StyledButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: ChkButtonVariant }>(({ theme, variant }) => {
  const commonLayoutStyles: CSSObject = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(5),
  };
  const commonBorderStyles: CSSObject = {
    border: theme.borders.borderWidth1,
    borderRadius: theme.borders.borderRadius2,
  };
  const commonTypographyStyles: CSSObject = {
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightMedium,
  };
  const commonStyles: CSSObject = {
    cursor: 'pointer',
    transition: `
      background-color 150ms ease-in,
      border-color 150ms ease-in,
      color 150ms ease-in
    `,
  };
  const ContainedOutlinedCommonStyles: CSSObject = {
    ...commonLayoutStyles,
    ...commonBorderStyles,
    ...commonTypographyStyles,
    ...commonStyles,
  };

  switch (variant) {
    case CHK_BUTTON_VARIANT.CONTAINED:
      return {
        ...ContainedOutlinedCommonStyles,
        color: theme.palette.white,
        backgroundColor: theme.palette.navy500,
        '&:hover': {
          backgroundColor: theme.palette.navy400,
          borderColor: theme.palette.navy100,
        },
        '&:focus': {
          backgroundColor: theme.palette.navy400,
          borderColor: theme.palette.navy500,
        },
        '&:disabled': {
          backgroundColor: theme.palette.navy50,
          color: theme.palette.navy200,
          borderColor: theme.palette.navy50,
        },
      };

    case CHK_BUTTON_VARIANT.OUTLINED:
      return {
        ...ContainedOutlinedCommonStyles,
        backgroundColor: theme.palette.white,
        color: theme.palette.navy500,
        '&:hover': {
          backgroundColor: theme.palette.navy50,
          color: theme.palette.steel500,
          borderColor: theme.palette.navy200,
        },
        '&:focus': {
          backgroundColor: theme.palette.navy50,
          color: theme.palette.steel500,
          borderColor: theme.palette.navy500,
        },
        '&:disabled': {
          backgroundColor: theme.palette.navy50,
          color: theme.palette.steel200,
          borderColor: theme.palette.navy50,
        },
      };

    case CHK_BUTTON_VARIANT.TEXT:
      return {
        ...ContainedOutlinedCommonStyles,
        borderColor: 'transparent',
        color: theme.palette.blue500,
        // TODO: Discuss it with Ali, not necessary for this specific implementation (PESF-398)
        '&:hover': {
          backgroundColor: theme.palette.blue50,
        },
        '&:focus': {
          backgroundColor: theme.palette.blue50,
        },
        '&:disabled': {
          color: theme.palette.steel200,
        },
      };
  }
});

const StartIconRoot = styled('span')(({ theme }) => ({
  display: 'inherit',
  marginRight: theme.spacing(2),
}));

const EndIconRoot = styled('span')(({ theme }) => ({
  display: 'inherit',
  marginLeft: theme.spacing(3),
}));
